<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (OFFICE RENTAL SEC
          DEPOSIT)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'">
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <!--            <v-checkbox v-model="is_search" label="Search?"-->
            <!--                        @change="selected_status(status)"></v-checkbox>-->
            <!--            <v-autocomplete-->
            <!--              v-if="is_search"-->
            <!--              dense-->
            <!--              outlined-->
            <!--              v-model="search"-->
            <!--              :loading="isLoading"-->
            <!--              @keyup.enter="searching($event.target.value)"-->
            <!--              hide-no-data-->
            <!--              hide-selected-->
            <!--              item-text="name"-->
            <!--              item-value="id"-->
            <!--              label="Search"-->
            <!--              class="mx-2"-->
            <!--              placeholder="Press Enter to Search "-->
            <!--              :prepend-icon="icons.mdiAccountSearchOutline"-->
            <!--            ></v-autocomplete>-->
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_amount" label="Total Amount"
                          readonly></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_balance" label="Total Balance"
                          readonly></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 md12 class="mx-2">
          LEGENDS -
          (SOA-
          <v-icon
            class="mr-2"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          )
          <div
            v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'">
            (BOD CHARGES-
            <v-icon
              class="mr-2"
              color="warning"
            >
              {{icons.mdiFolderSwap}}
            </v-icon>
            ),
            (DELETE-
            <v-icon
              class="mr-2"
              color="error"
            >
              {{icons.mdiDelete}}
            </v-icon>
            )
          </div>

        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'?tabs:tabs1"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.office_sec_deposit==null?'':item.office_sec_deposit.branch.branch_code
                      }}
                    </td>
                    <td>
                      {{ item.office_sec_deposit==null?'':item.office_sec_deposit.bldg_owner }}
                    </td>
                    <td>
                      {{ item.office_sec_deposit==null?'':item.office_sec_deposit.no_of_months }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      {{
                      item.office_sec_deposit==null?'':(item.office_sec_deposit.request_data.cv!=null?'CV#'+item.office_sec_deposit.request_data.cv.voucher_no:(item.office_sec_deposit.request_data.ckv!=null?'CKV#'+item.office_sec_deposit.request_data.ckv.voucher_no:(item.office_sec_deposit.request_data.ftv!=null?'FTV#'+item.office_sec_deposit.request_data.ftv.voucher_no:'')))
                      }}
                    </td>
                    <td>
                      {{ item.office_sec_deposit==null?'':item.office_sec_deposit.remarks }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="error"
                        @click="delete_o(item)"
                      >
                        {{icons.mdiDelete}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter, mdiTransferRight, mdiFolderSwap, mdiDelete,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Branch', value: 'name', sortable: true},
        {text: 'Bldg. Owner', value: 'particulars', sortable: true},
        {text: 'No. of Deposits', value: 'purpose', sortable: true},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Voucher #', value: 'tin', sortable: false},
        {text: 'Remarks', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      data_items3: [],
      tab: '',
      tabs: [
        {title: 'OFFICE SEC. DEPOSIT (for refund)', icon: mdiAccountOutline},
      ],
      tabs1: [
        {title: 'EMPLOYEE', icon: mdiGoogleCirclesCommunities},
      ],
      is_search: false,
      status: '',
      search: '',
      isLoading: false,
      total_amount: '0',
      total_balance: '0',
      id: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiFolderSwap,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD')) {
        this.status = 'Unpaid'
        this.searching(this.employee_id)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('other_receivables', ['list_of_office_sec_deposit_receivable', 'change_orec_to_bod_charge', 'delete_orec', 'search_list_of_other_receivable']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      balance_calculation(item) {
        var total = item.amount
        var payment = 0
        item.payment.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        var balance = (total - payment)

        return this.formatPrice(balance)
      },
      searching(value) {
        this.search = value
        this.data_items = []
        this.data_items2 = []
        this.data_items3 = []
        this.total_amount = '0'
        this.total_balance = '0'
        this.search_list_of_other_receivable({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount = response.data[0].total_amount
            this.total_balance = response.data[0].total_balance
            this.data_items = response.data[0].employee
            this.data_items2 = response.data[0].others
            this.data_items3 = response.data[0].sec_deposit
            this.isLoading = false
          })
      },
      selected_status(value) {
        this.data_items = []
        this.data_items2 = []
        this.data_items3 = []
        this.total_amount = '0'
        this.total_balance = '0'
        if (!this.is_search) {
          this.list_of_office_sec_deposit_receivable({
            status: value
          })
            .then(response => {
              this.total_amount = response.data[0].total_amount
              this.total_balance = response.data[0].total_balance
              this.data_items3 = response.data[0].sec_deposit
              this.isLoading = false
            })
        } else {
          this.searching(this.search)
        }
      },
      bod_charge(item) {
        if (confirm("PROCEED?")) {
          this.change_orec_to_bod_charge({
            id: item.id
          })
            .then(() => {
              this.selected_status(this.status)
            })
        }
      },
      delete_o(item) {
        this.delete_orec({
          id: item.id
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.selected_status(this.status)
          })
      },
      print_soa(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'HILARIO GENERAL ENTERPRISE',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'OTHERE RECEIVABLE - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
    }
  }
</script>
